import { Avatar, Card, CardContent, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getGoIpCredit } from "src/actions/goip";
import moment from "moment";
import { MdAttachMoney, MdDateRange } from "react-icons/md";

const GoIp = (props) => {
  const dispatch = useDispatch();
  const { creditBalance } = useSelector((state) => state.goIp);

  useEffect(() => console.log(creditBalance), [creditBalance]);

  useEffect(() => {
    dispatch(getGoIpCredit());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Grid container spacing={1}>
      <Grid item md={6} xs={12}>
        <Card sx={{ height: "100%" }} {...props}>
          <CardContent>
            <Grid
              container
              spacing={1}
              sx={{ justifyContent: "space-between" }}
            >
              <Grid item>
                <Typography color="textSecondary" gutterBottom variant="h6">
                  GOIP BALANCE
                </Typography>
                <Typography color="textPrimary" variant="h3">
                  {creditBalance ? creditBalance.balance?.toLocaleString() : ""}{" "}
                  IQD
                </Typography>
              </Grid>
              <Grid item>
                <Avatar
                  sx={{
                    backgroundColor: "#0089FF",
                    height: 56,
                    width: 56,
                    padding: 1,
                  }}
                >
                  <MdAttachMoney size={30} />
                </Avatar>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={6} xs={12}>
        <Card sx={{ height: "100%" }} {...props}>
          <CardContent>
            <Grid
              container
              spacing={3}
              sx={{ justifyContent: "space-between" }}
            >
              <Grid item>
                <Typography color="textSecondary" gutterBottom variant="h6">
                  GOIP EXPIRY DATE
                </Typography>
                <Typography color="textPrimary" variant="h3">
                  {creditBalance
                    ? moment(creditBalance.expireAt).format("YYYY-MM-DD")
                    : ""}{" "}
                </Typography>
              </Grid>
              <Grid item>
                <Avatar
                  sx={{
                    backgroundColor: "#F22F46",
                    height: 56,
                    width: 56,
                  }}
                >
                  <MdDateRange size={30} />
                </Avatar>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default GoIp;
