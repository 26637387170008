import {
  GET_ALL_GO_IP,
  GET_CREDIT_GO_IP,
  OPEN_GO_IP_DIALOG,
  RELOAD_GO_IP,
  SAVE_GO_IP_SAVE_NO,
  SAVE_GO_IP_SEARCH_BODY,
} from "../actions/type";

const initialState = {
  reload: false,
  openDialog: false,
  saveGoIpPageNum: 0,
  pageSize: 15,
  data: {
    data: [],
    total: 0,
    rowCount: 0,
  },
  creditBalance: {},
  searchBody: {
    status: null,
    fromCreatedAt: null,
    toCreatedAt: null,
    pageSize: 15,
  },
  getById: {},
};

function goIpReducer(goIp = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_GO_IP:
      goIp.data.data = payload.data;
      goIp.data.total = payload.total;
      goIp.data.rowCount = payload.rowCount;
      return goIp;

    case GET_CREDIT_GO_IP:
      goIp.creditBalance = payload;
      return goIp;

    case SAVE_GO_IP_SAVE_NO:
      return { ...goIp, saveGoIpPageNum: payload };

    case SAVE_GO_IP_SEARCH_BODY:
      return { ...goIp, searchBody: payload };

    case OPEN_GO_IP_DIALOG:
      return { ...goIp, openDialog: !goIp.openDialog };

    case RELOAD_GO_IP:
      return { ...goIp, reload: !goIp.reload };

    default:
      return goIp;
  }
}

export default goIpReducer;
