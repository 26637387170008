import { useState, useEffect } from "react";
import {
  Link,
  Link as RouterLink,
  useLocation,
  matchPath,
} from "react-router-dom";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Divider,
  Typography,
  Button,
  Skeleton,
  IconButton,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import InputIcon from "@mui/icons-material/Input";
import Cookies from "universal-cookie";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarContent,
} from "react-pro-sidebar";
import "../theme/custom.scss";
import { Icon } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Footer from "./Footer";
import { Stack } from "@mui/system";
import helper from "src/utils/helper";
import { BiArrowFromLeft, BiArrowFromRight } from "react-icons/bi";
import { sidebarDashboard } from "src/actions/users";
import { useTheme } from "@emotion/react";
import { FaInfoCircle } from "react-icons/fa";

const user = {
  maleAvatar: "/static/images/avatars/user.jpg",
  femaleAvatar: "/static/images/avatars/female_user.jpg",
  jobTitle: "",
  name: "USER",
};

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const theme = useTheme();
  const matchUpXl = useMediaQuery(theme.breakpoints.up("xl"));

  const location = useLocation();
  const dispatch = useDispatch();

  const [openVendors, setOpenVendors] = useState(false);
  const [openConfig, setOpenConfig] = useState(false);
  const [openTicket, setOpenTicket] = useState(false);
  const [openDeals, setOpenDeals] = useState(false);
  const [openReport, setOpenReport] = useState(false);
  const [openGiftItems, setOpenGiftItems] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState("");

  const userInformation = useSelector((state) => state.users.userInfo);
  const _UserInfo = useSelector((state) => state.users.userInfo);
  const _Collapsed = useSelector((state) => state.users.sidebarDashboard);

  var collapse = JSON.parse(localStorage.getItem("collapseSidebar"));

  useEffect(() => {
    if (matchUpXl) {
      dispatch(sidebarDashboard(false));
    } else {
      dispatch(sidebarDashboard(true));
    }
  }, [dispatch, matchUpXl]);

  useEffect(() => {
    setOpenVendors(
      ["/app/vendors/all_vendors", "/app/vendors/vendors_Category"].includes(
        location.pathname
      )
    );
    if (
      ["/app/vendors/all_vendors", "/app/vendors/vendors_Category"].includes(
        location.pathname
      )
    )
      setSelectedMenu("Vendors");
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  useEffect(() => {
    setOpenConfig(
      [
        "/app/agent_city_config",
        "/app/shipping_config",
        "/app/addresses",
        "/app/reasons",
        // "/app/expense_types",
        "/app/mobile_app_version",
        "/app/image_upload_center",
        "/app/System_Settings",
        "/app/General_Settings",
        "/app/E-Payments",
        "/app/contact_info",
        "/app/onboarding",
        "/app/go_ip",
      ].includes(location.pathname)
    );
    if (
      [
        "/app/agent_city_config",
        "/app/shipping_config",
        "/app/addresses",
        "/app/reasons",
        // "/app/expense_types",
        "/app/mobile_app_version",
        "/app/image_upload_center",
        "/app/System_Settings",
        "/app/General_Settings",
        "/app/E-Payments",
        "/app/contact_info",
        "/app/onboarding",
        "/app/go_ip",
      ].includes(location.pathname)
    )
      setSelectedMenu("Settings");
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  useEffect(() => {
    setOpenTicket(
      [
        "/app/ticket/allTickets",
        "/app/ticket/ticketCategory",
        "/app/ticket/statistic",
        "/app/Department/DepartmentConfig",
        "/app/Department/Employee",
      ].includes(location.pathname)
    );
    if (
      [
        "/app/ticket/allTickets",
        "/app/ticket/ticketCategory",
        "/app/ticket/statistic",
        "/app/Department/DepartmentConfig",
        "/app/Department/Employee",
      ].includes(location.pathname)
    )
      setSelectedMenu("Ticket");
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  useEffect(() => {
    setOpenDeals(
      [
        "/app/deals/promoted_items",
        "/app/deals/deals",
        "/app/promos",
        "/app/banners",
        "/app/promoted_items",
        "/app/home_items",
        "/app/rating_questions",
        "/app/order_rating",
        "/app/pop_up_post",
      ].includes(location.pathname)
    );
    if (
      [
        "/app/deals/promoted_items",
        "/app/deals/deals",
        "/app/banners",
        "/app/promoted_items",
        "/app/home_items",
        "/app/promos",
        "/app/rating_questions",
        "/app/order_rating",
        "/app/pop_up_post",
      ].includes(location.pathname)
    )
      setSelectedMenu("Advertisement");
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  useEffect(() => {
    setOpenReport(
      ["/app/reports", "/app/wallet_reports"].includes(location.pathname)
    );
    if (["/app/reports", "/app/wallet_reports"].includes(location.pathname))
      setSelectedMenu("Reports");
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  useEffect(() => {
    setOpenGiftItems(
      [
        "/app/gifts/items",
        "/app/gifts/item_category",
        "/app/gifts/supplier",
        "/app/gifts/brand",
        "/app/gifts/item_options",
        "/app/gifts/wrapping",
        "/app/gifts/collections",
      ].includes(location.pathname)
    );
    if (
      [
        "/app/gifts/items",
        "/app/gifts/item_category",
        "/app/gifts/supplier",
        "/app/gifts/brand",
        "/app/gifts/item_options",
        "/app/gifts/wrapping",
        "/app/gifts/collections",
      ].includes(location.pathname)
    )
      setSelectedMenu("Items");
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const handleMenuClick = (title) => {
    setSelectedMenu(title);
    if (title === "Vendors") {
      if (openVendors === true) {
        setOpenVendors(false);
        setOpenConfig(false);
        setOpenTicket(false);
        setOpenDeals(false);
        setOpenReport(false);
        setOpenGiftItems(false);
      } else {
        setOpenVendors(true);
        setOpenConfig(false);
        setOpenTicket(false);
        setOpenDeals(false);
        setOpenReport(false);
        setOpenGiftItems(false);
      }
    } else if (title === "Settings") {
      if (openConfig === true) {
        setOpenConfig(false);
        setOpenTicket(false);
        setOpenVendors(false);
        setOpenDeals(false);
        setOpenReport(false);
        setOpenGiftItems(false);
      } else {
        setOpenConfig(true);
        setOpenTicket(false);
        setOpenVendors(false);
        setOpenDeals(false);
        setOpenReport(false);
        setOpenGiftItems(false);
      }
    } else if (title === "Ticket") {
      if (openTicket === true) {
        setOpenConfig(false);
        setOpenTicket(false);
        setOpenVendors(false);
        setOpenDeals(false);
        setOpenReport(false);
        setOpenGiftItems(false);
      } else {
        setOpenConfig(false);
        setOpenTicket(true);
        setOpenVendors(false);
        setOpenDeals(false);
        setOpenReport(false);
      }
    } else if (title === "Advertisement") {
      if (openDeals === true) {
        setOpenDeals(false);
        setOpenConfig(false);
        setOpenTicket(false);
        setOpenVendors(false);
        setOpenReport(false);
        setOpenGiftItems(false);
      } else {
        setOpenDeals(true);
        setOpenConfig(false);
        setOpenTicket(false);
        setOpenVendors(false);
        setOpenReport(false);
        setOpenGiftItems(false);
      }
    } else if (title === "Reports") {
      if (openReport === true) {
        setOpenReport(false);
        setOpenDeals(false);
        setOpenConfig(false);
        setOpenTicket(false);
        setOpenVendors(false);
        setOpenGiftItems(false);
      } else {
        setOpenReport(true);
        setOpenDeals(false);
        setOpenConfig(false);
        setOpenTicket(false);
        setOpenVendors(false);
        setOpenGiftItems(false);
      }
    } else if (title === "Items") {
      if (openGiftItems === true) {
        setOpenReport(false);
        setOpenDeals(false);
        setOpenConfig(false);
        setOpenTicket(false);
        setOpenVendors(false);
        setOpenGiftItems(false);
      } else {
        setOpenGiftItems(true);
        setOpenReport(false);
        setOpenDeals(false);
        setOpenConfig(false);
        setOpenTicket(false);
        setOpenVendors(false);
      }
    }
  };

  const items =
    _UserInfo.serviceType === "Global"
      ? [
          {
            href: "/app/dashboard",
            icon: "dashboard",
            title: "Dashboard",
            role: _UserInfo?.roleOEndpoint?.filter(
              (api) => api.Code === "07-01"
            )[0]
              ? true
              : false,
          },
          {
            href: "/app/users",
            icon: "people_alt",
            title: "Users",
            role:
              _UserInfo?.roleOEndpoint?.filter(
                (api) => api.Code === "31-10"
              )[0] ||
              _UserInfo?.roleOEndpoint?.filter((api) => api.Code === "31-19")[0]
                ? true
                : false,
          },
          {
            href: "/app/incomplete_carts",
            icon: "people_alt",
            title: "Incomplete Carts",
            role: _UserInfo?.roleOEndpoint?.filter(
              (api) => api.Code === "06-18"
            )[0]
              ? true
              : false,
          },
          {
            href: "/app/Dynamic_Auth",
            icon: "assignment_ind",
            title: "Users Roles",
            role: _UserInfo.roles.includes("SystemAdmin"),
          },
          {
            href: "/app/vendors/all_vendors",
            icon: "branding_watermark",
            title: "Vendors",
            role:
              _UserInfo?.roleOEndpoint?.filter(
                (api) => api.Code === "33-06"
              )[0] ||
              _UserInfo?.roleOEndpoint?.filter((api) => api.Code === "35-04")[0]
                ? true
                : false,
          },
          {
            href: "/app/orders",
            icon: "fact_check",
            title: "Orders",
            role: _UserInfo?.roleOEndpoint?.filter(
              (api) => api.Code === "22-01"
            )[0]
              ? true
              : false,
          },
          {
            href: "/app/order_items",
            icon: "view_in_ar",
            title: "Order Items",
            role: _UserInfo?.roleOEndpoint?.filter(
              (api) => api.Code === "23-01"
            )[0]
              ? true
              : false,
          },
          {
            href: "/app/shipments",
            icon: "inventory",
            title: "Shipments",
            role: _UserInfo?.roleOEndpoint?.filter(
              (api) => api.Code === "59-02"
            )[0]
              ? true
              : false,
          },
          {
            href: "/app/purchasing_bill",
            icon: "receipt",
            title: "Purchasing Bill",
            role: _UserInfo?.roleOEndpoint?.filter(
              (api) => api.Code === "61-02"
            )[0]
              ? true
              : false,
          },
          {
            href: "/app/external_item",
            icon: "card_travel",
            title: "External Item",
            role: _UserInfo?.roleOEndpoint?.filter(
              (api) => api.Code === "13-03"
            )[0]
              ? true
              : false,
          },
          {
            href: "/app/deals",
            icon: "bar_chart",
            title: "Advertisement",
            role:
              _UserInfo?.roleOEndpoint?.filter(
                (api) =>
                  api.Code === "02-05" ||
                  api.Code === "25-05" ||
                  api.Code === "36-03" ||
                  api.Code === "37-04" ||
                  api.Controller === "HomeItems" ||
                  api.Code === "52-03" ||
                  api.Code === "53-03" ||
                  api.Code === "24-05"
              )[0] ||
              _UserInfo?.roleOEndpoint?.filter((api) => api.Code === "37-04")[0]
                ? true
                : false,
            children: [
              {
                href: "/app/promos",
                icon: "price_change",
                title: "Promo Codes",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) => api.Code === "25-05"
                )[0]
                  ? true
                  : false,
              },
              {
                href: "/app/banners",
                icon: "view_carousel",
                title: "Banners",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) => api.Code === "02-05"
                )[0]
                  ? true
                  : false,
              },
              {
                href: "/app/pop_up_post",
                icon: "featured_video",
                title: "Popup Post",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) => api.Code === "24-05"
                )[0]
                  ? true
                  : false,
              },
              {
                href: "/app/deals/promoted_items",
                icon: "trending_down",
                title: "Promoted items",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) => api.Code === "36-03"
                )[0]
                  ? true
                  : false,
              },
              {
                href: "/app/deals/deals",
                icon: "discount",
                title: "Deals",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) => api.Code === "37-04"
                )[0]
                  ? true
                  : false,
              },
              {
                href: "/app/home_items",
                icon: "home",
                title: "Home Items",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) => api.Code === "02-05"
                )[0]
                  ? true
                  : false,
              },
              {
                href: "/app/rating_questions",
                icon: "star_border",
                title: "Rating Questions",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) => api.Code === "52-03"
                )[0]
                  ? true
                  : false,
              },
              {
                href: "/app/order_rating",
                icon: "star_half",
                title: "Order Ratings",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) => api.Code === "53-03"
                )[0]
                  ? true
                  : false,
              },
            ],
          },
          {
            href: "/app/cart_reviews",
            icon: "rate_review",
            title: "Cart Reviews",
            role: _UserInfo?.roleOEndpoint?.filter(
              (api) => api.Code === "06-07"
            )[0]
              ? true
              : false,
          },
          {
            href: "/app/agent_requests",
            icon: "menu_book",
            title: "Agent Requests",
            role: _UserInfo?.roleOEndpoint?.filter(
              (api) => api.Code === "04-01"
            )[0]
              ? true
              : false,
          },
          {
            href: "/app/referral_code",
            icon: "diversity_3",
            title: "Referral Code",
            role: _UserInfo?.roleOEndpoint?.filter(
              (api) => api.Code === "26-01"
            )[0]
              ? true
              : false,
          },
          {
            href: "/app/faq",
            icon: "help",
            title: "FAQ",
            role: _UserInfo?.roleOEndpoint?.filter(
              (api) => api.Code === "50-03"
            )[0]
              ? true
              : false,
          },
          // {
          //   href: "/app/wallet_reports",
          //   icon: "report",
          //   title: "Wallet Report",
          //   role: _UserInfo?.roleOEndpoint?.filter(
          //     (api) => api.Code === "34-13"
          //   )[0]
          //     ? true
          //     : false,
          // },
          {
            href: "",
            icon: "summarize",
            title: "Reports",
            role: _UserInfo?.roleOEndpoint?.filter(
              (api) => api.Controller === "Report" || api.Code === "34-13"
            )[0]
              ? true
              : false,
            children: [
              {
                href: "/app/reports",
                icon: "summarize",
                title: "All Reports",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) =>
                    api.Controller === "Report" ||
                    api.Controller === "Gift Report"
                )[0]
                  ? true
                  : false,
              },
              {
                href: "/app/wallet_reports",
                icon: "report",
                title: "Wallet Report",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) => api.Code === "34-13"
                )[0]
                  ? true
                  : false,
              },
            ],
          },
          {
            href: "",
            icon: "settings",
            title: "Settings",
            role: _UserInfo?.roleOEndpoint?.filter(
              (api) =>
                api.Code === "03-04" ||
                api.Code === "28-05" ||
                api.Code === "09-01" ||
                api.Code === "15-03" ||
                api.Controller === "Address" ||
                api.Code === "16-05" ||
                api.Code === "21-05" ||
                api.Code === "32-05" ||
                api.Code === "29-05" ||
                api.Code === "11-05" ||
                api.Code === "18-05" ||
                api.Code === "56-02"
            )[0]
              ? true
              : false,
            children: [
              {
                href: "/app/agent_city_config",
                icon: "local_taxi",
                title: "Agent City Config.",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) => api.Code === "03-04"
                )[0]
                  ? true
                  : false,
              },
              {
                href: "/app/image_upload_center",
                icon: "add_photo_alternate",
                title: "Image Upload Center",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) => api.Code === "28-05"
                )[0]
                  ? true
                  : false,
              },
              {
                href: "/app/onboarding",
                icon: "collections",
                title: "Onboarding Images",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) => api.Code === "57-02"
                )[0]
                  ? true
                  : false,
              },
              {
                href: "/app/E-Payments",
                icon: "payments",
                title: "E-Payments",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) => api.Code === "09-01"
                )[0]
                  ? true
                  : false,
              },
              {
                href: "/app/General_Settings",
                icon: "app_settings_alt",
                title: "General Settings",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) => api.Code === "15-03"
                )[0]
                  ? true
                  : false,
              },
              {
                href: "/app/addresses",
                icon: "pin_drop",
                title: "Addresses",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) => api.Controller === "Address"
                )[0]
                  ? true
                  : false,
              },
              {
                href: "/app/reasons",
                icon: "unsubscribe",
                title: "Reasons",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) => api.Code === "16-05"
                )[0]
                  ? true
                  : false,
              },
              {
                href: "/app/shipping_config",
                title: "Variables",
                icon: "currency_exchange",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) => api.Code === "29-05"
                )[0]
                  ? true
                  : false,
              },
              {
                href: "/app/contact_info",
                title: "Contact Info",
                icon: "contact_page",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) => api.Code === "56-02"
                )[0]
                  ? true
                  : false,
              },
              // {
              //   href: "/app/expense_types",
              //   title: "Expense Types",
              //   icon: "list_alt",
              //   role: _UserInfo?.roleOEndpoint?.filter(
              //     (api) => api.Code === "11-05"
              //   )[0]
              //     ? true
              //     : false,
              // },
              {
                href: "/app/mobile_app_version",
                title: "Mobile App Version",
                icon: "phone_android",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) => api.Code === "18-05"
                )[0]
                  ? true
                  : false,
              },
              {
                href: "/app/go_ip",
                title: "GoIP Messages",
                icon: "phone_android",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) => api.Code === "66-03"
                )[0]
                  ? true
                  : false,
              },
            ],
          },

          {
            href: "",
            icon: "confirmation_number",
            title: "Ticket",
            role: _UserInfo?.roleOEndpoint?.filter(
              (api) => api.Code === "T3-06"
            )[0]
              ? true
              : false,
            children: [
              {
                href: "/app/ticket/statistic",
                icon: "bar_chart",
                title: "Statistic",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) => api.Code === "T2-01"
                )[0]
                  ? true
                  : false,
              },
              {
                href: "/app/ticket/allTickets",
                icon: "book_online",
                title: "All Tickets",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) => api.Code === "T3-06"
                )[0]
                  ? true
                  : false,
              },
              {
                href: "/app/ticket/ticketCategory",
                icon: "category",
                title: "Category",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) => api.Code === "T1-05"
                )[0]
                  ? true
                  : false,
              },
              {
                href: "/app/Department/DepartmentConfig",
                icon: "domain_add",
                title: "Department",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) => api.Code === "D1-05"
                )[0]
                  ? true
                  : false,
              },
              {
                href: "/app/Department/Employee",
                icon: "badge",
                title: "Employee",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) => api.Code === "D3-05"
                )[0]
                  ? true
                  : false,
              },
            ],
          },
          // {
          //   href: "/app/Department",
          //   icon: "location_city",
          //   title: "Department",
          //   role: _UserInfo?.roleOEndpoint?.filter(
          //     (api) => api.Code === "D1-05"
          //   )[0]
          //     ? true
          //     : false,
          //   children: [
          //     {
          //       href: "/app/Department/DepartmentConfig",
          //       icon: "domain_add",
          //       title: "Department Management",
          //       role: _UserInfo?.roleOEndpoint?.filter(
          //         (api) => api.Code === "D1-05"
          //       )[0]
          //         ? true
          //         : false,
          //     },
          //     {
          //       href: "/app/Department/Employee",
          //       icon: "badge",
          //       title: "Employee",
          //       role: _UserInfo?.roleOEndpoint?.filter(
          //         (api) => api.Code === "D3-05"
          //       )[0]
          //         ? true
          //         : false,
          //     },
          //   ],
          // },
        ]
      : [
          {
            href: "/app/gift_dashboard",
            icon: "dashboard",
            title: "Dashboard",
            role: _UserInfo?.roleOEndpoint?.filter(
              (api) => api.Code === "07-01"
            )[0]
              ? true
              : false,
          },
          {
            href: "/app/users",
            icon: "people_alt",
            title: "Users",
            role:
              _UserInfo?.roleOEndpoint?.filter(
                (api) => api.Code === "31-10"
              )[0] ||
              _UserInfo?.roleOEndpoint?.filter((api) => api.Code === "31-19")[0]
                ? true
                : false,
          },
          {
            href: "/app/Dynamic_Auth",
            icon: "assignment_ind",
            title: "Users Roles",
            role: _UserInfo.roles.includes("SystemAdmin"),
          },
          {
            href: "/app/gifts/customers_cart",
            icon: "shopping_cart",
            title: "Customers Cart",
            role: _UserInfo?.roleOEndpoint?.filter(
              (api) => api.Code === "42-02"
            )[0]
              ? true
              : false,
          },
          {
            href: "",
            icon: "bar_chart",
            title: "Items",
            role: _UserInfo?.roleOEndpoint?.filter(
              (api) =>
                api.Code === "41-03" ||
                api.Code === "40-03" ||
                api.Code === "54-03" ||
                api.Code === "44-01" ||
                api.Code === "43-01" ||
                api.Code === "55-03"
            )[0],
            children: [
              {
                href: "/app/gifts/items",
                icon: "redeem",
                title: "Products",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) => api.Code === "41-03"
                )[0]
                  ? true
                  : false,
              },
              {
                href: "/app/gifts/collections",
                icon: "collections",
                title: "Collections",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) => api.Code === "62-03"
                )[0]
                  ? true
                  : false,
              },
              {
                href: "/app/gifts/item_category",
                icon: "category",
                title: "Category",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) => api.Code === "40-03"
                )[0]
                  ? true
                  : false,
              },
              {
                href: "/app/gifts/supplier",
                icon: "local_shipping",
                title: "Supplier",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) => api.Code === "54-03"
                )[0]
                  ? true
                  : false,
              },

              {
                href: "/app/gifts/brand",
                icon: "branding_watermark",
                title: "Brand",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) => api.Code === "55-03"
                )[0]
                  ? true
                  : false,
              },
              {
                href: "/app/gifts/item_options",
                icon: "style",
                title: "Options",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) => api.Code === "43-01"
                )[0]
                  ? true
                  : false,
              },
              {
                href: "/app/gifts/wrapping",
                icon: "redeem",
                title: "Wrapping",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) => api.Code === "44-01"
                )[0]
                  ? true
                  : false,
              },
            ],
          },
          {
            href: "/app/gifts/orders",
            icon: "fact_check",
            title: "Orders",
            role: _UserInfo?.roleOEndpoint?.filter(
              (api) => api.Code === "47-07"
            )[0]
              ? true
              : false,
          },
          {
            href: "",
            icon: "bar_chart",
            title: "Advertisement",
            role:
              _UserInfo?.roleOEndpoint?.filter(
                (api) =>
                  api.Code === "02-05" ||
                  api.Code === "25-05" ||
                  api.Code === "36-03" ||
                  api.Code === "37-04" ||
                  api.Controller === "HomeItems" ||
                  api.Code === "52-03" ||
                  api.Code === "53-03" ||
                  api.Code === "24-05"
              )[0] ||
              _UserInfo?.roleOEndpoint?.filter((api) => api.Code === "37-04")[0]
                ? true
                : false,
            children: [
              {
                href: "/app/promos",
                icon: "price_change",
                title: "Promo Codes",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) => api.Code === "25-05"
                )[0]
                  ? true
                  : false,
              },
              {
                href: "/app/banners",
                icon: "view_carousel",
                title: "Banners",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) => api.Code === "02-05"
                )[0]
                  ? true
                  : false,
              },
              {
                href: "/app/pop_up_post",
                icon: "featured_video",
                title: "Popup Post",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) => api.Code === "24-05"
                )[0]
                  ? true
                  : false,
              },
              {
                href: "/app/rating_questions",
                icon: "star_border",
                title: "Rating Questions",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) => api.Code === "52-03"
                )[0]
                  ? true
                  : false,
              },
              {
                href: "/app/order_rating",
                icon: "star_half",
                title: "Order Ratings",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) => api.Code === "53-03"
                )[0]
                  ? true
                  : false,
              },
            ],
          },
          {
            href: "/app/events",
            icon: "calendar_month",
            title: "Events",
            role: _UserInfo?.roleOEndpoint?.filter(
              (api) => api.Code === "51-03"
            )[0]
              ? true
              : false,
          },
          {
            href: "/app/user_addresses",
            icon: "place",
            title: "User Addresses",
            role: _UserInfo?.roleOEndpoint?.filter(
              (api) => api.Code === "46-03"
            )[0]
              ? true
              : false,
          },
          {
            href: "/app/favorite",
            icon: "favorite",
            title: "Favorite",
            role: _UserInfo?.roleOEndpoint?.filter(
              (api) => api.Code === "46-03"
            )[0]
              ? true
              : false,
          },
          {
            href: "/app/faq",
            icon: "help",
            title: "FAQ",
            role: _UserInfo?.roleOEndpoint?.filter(
              (api) => api.Code === "50-03"
            )[0]
              ? true
              : false,
          },
          {
            href: "",
            icon: "settings",
            title: "Settings",
            role: _UserInfo?.roleOEndpoint?.filter(
              (api) =>
                api.Code === "03-04" ||
                api.Code === "28-05" ||
                api.Code === "09-01" ||
                api.Code === "15-03" ||
                api.Controller === "Address" ||
                api.Code === "16-05" ||
                api.Code === "21-05" ||
                api.Code === "32-05" ||
                api.Code === "29-05" ||
                api.Code === "11-05" ||
                api.Code === "18-05" ||
                api.Code === "56-02"
            )[0]
              ? true
              : false,
            children: [
              {
                href: "/app/agent_city_config",
                icon: "local_taxi",
                title: "Agent City Config.",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) => api.Code === "03-04"
                )[0]
                  ? true
                  : false,
              },
              {
                href: "/app/image_upload_center",
                icon: "add_photo_alternate",
                title: "Image Upload Center",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) => api.Code === "28-05"
                )[0]
                  ? true
                  : false,
              },
              {
                href: "/app/onboarding",
                icon: "collections",
                title: "Onboarding Images",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) => api.Code === "57-02"
                )[0]
                  ? true
                  : false,
              },
              {
                href: "/app/E-Payments",
                icon: "payments",
                title: "E-Payments",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) => api.Code === "09-01"
                )[0]
                  ? true
                  : false,
              },
              {
                href: "/app/General_Settings",
                icon: "app_settings_alt",
                title: "General Settings",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) => api.Code === "15-03"
                )[0]
                  ? true
                  : false,
              },
              {
                href: "/app/addresses",
                icon: "pin_drop",
                title: "Addresses",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) => api.Controller === "Address"
                )[0]
                  ? true
                  : false,
              },
              {
                href: "/app/reasons",
                icon: "unsubscribe",
                title: "Reasons",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) => api.Code === "16-05"
                )[0]
                  ? true
                  : false,
              },
              {
                href: "/app/shipping_config",
                title: "Variables",
                icon: "currency_exchange",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) => api.Code === "29-05"
                )[0]
                  ? true
                  : false,
              },
              {
                href: "/app/contact_info",
                title: "Contact Info",
                icon: "contact_page",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) => api.Code === "56-02"
                )[0]
                  ? true
                  : false,
              },
              // {
              //   href: "/app/expense_types",
              //   title: "Expense Types",
              //   icon: "list_alt",
              //   role: _UserInfo?.roleOEndpoint?.filter(
              //     (api) => api.Code === "11-05"
              //   )[0]
              //     ? true
              //     : false,
              // },
              {
                href: "/app/mobile_app_version",
                title: "Mobile App Version",
                icon: "phone_android",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) => api.Code === "18-05"
                )[0]
                  ? true
                  : false,
              },
            ],
          },
          {
            href: "",
            icon: "summarize",
            title: "Reports",
            role: _UserInfo?.roleOEndpoint?.filter(
              (api) => api.Controller === "Report" || api.Code === "34-13"
            )[0]
              ? true
              : false,
            children: [
              {
                href: "/app/reports",
                icon: "summarize",
                title: "All Reports",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) =>
                    api.Controller === "Report" ||
                    api.Controller === "Gift Report"
                )[0]
                  ? true
                  : false,
              },
              {
                href: "/app/wallet_reports",
                icon: "report",
                title: "Wallet Report",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) => api.Code === "34-13"
                )[0]
                  ? true
                  : false,
              },
            ],
          },
          {
            href: "",
            icon: "confirmation_number",
            title: "Ticket",
            role: _UserInfo?.roleOEndpoint?.filter(
              (api) =>
                api.Code === "T3-06" ||
                api.Code === "D1-05" ||
                api.Code === "D2-02" ||
                api.Code === "D3-05"
            )[0]
              ? true
              : false,
            children: [
              {
                href: "/app/ticket/statistic",
                icon: "bar_chart",
                title: "Statistic",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) => api.Code === "T2-01"
                )[0]
                  ? true
                  : false,
              },
              {
                href: "/app/ticket/allTickets",
                icon: "book_online",
                title: "All Tickets",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) => api.Code === "T3-06"
                )[0]
                  ? true
                  : false,
              },
              {
                href: "/app/ticket/ticketCategory",
                icon: "category",
                title: "Category",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) => api.Code === "T1-05"
                )[0]
                  ? true
                  : false,
              },
              {
                href: "/app/Department/DepartmentConfig",
                icon: "domain_add",
                title: "Department",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) => api.Code === "D1-05"
                )[0]
                  ? true
                  : false,
              },
              {
                href: "/app/Department/Employee",
                icon: "badge",
                title: "Employee",
                role: _UserInfo?.roleOEndpoint?.filter(
                  (api) => api.Code === "D3-05"
                )[0]
                  ? true
                  : false,
              },
            ],
          },
        ];

  const isActive = (path) =>
    path
      ? !!matchPath(
          {
            path: path,
            end: false,
          },
          location.pathname
        )
      : false;

  const cookies = new Cookies();

  const content = (
    <ProSidebar collapsed={collapse}>
      <PerfectScrollbar style={{ display: "flex", flexDirection: "column" }}>
        <SidebarHeader style={{ backgroundColor: "white" }}>
          <Stack direction="row" justifyContent="end" sx={{ marginRight: 2 }}>
            <IconButton
              onClick={() => {
                dispatch(sidebarDashboard(!collapse));
              }}
            >
              {collapse ? (
                <BiArrowFromLeft size={30} />
              ) : (
                <BiArrowFromRight size={30} />
              )}
            </IconButton>
          </Stack>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              p: `${collapse ? "10px" : 0} 10px 10px 10px`,
            }}
          >
            {_UserInfo.id ? (
              <Avatar
                component={RouterLink}
                src={
                  userInformation.gender == 0
                    ? user.maleAvatar
                    : user.femaleAvatar
                }
                sx={{
                  cursor: "pointer",
                  width: 64,
                  height: 64,
                }}
                to="/app/settings"
              />
            ) : (
              <Avatar
                sx={{
                  cursor: "pointer",
                  width: 64,
                  height: 64,
                }}
              />
            )}
            {_UserInfo.id ? (
              !collapse && (
                <Typography color="text.secondary" variant="body2">
                  {_UserInfo?.firstName ?? ""} {_UserInfo?.secondName ?? ""}{" "}
                  {_UserInfo?.lastName ?? ""}
                </Typography>
              )
            ) : (
              <Skeleton
                sx={{ width: "100px", height: "7px", margin: 0.8 }}
                variant="rounded"
                animation="wave"
              />
            )}
            {_UserInfo.id ? (
              !collapse && (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  spacing={1}
                  marginLeft={
                    _UserInfo.roles.filter((role) => role === "SystemAdmin")[0]
                      ? 3
                      : 0
                  }
                >
                  <Typography color="primary" variant="body2">
                    {_UserInfo.roles
                      .filter((role) => role === "SystemAdmin")[0]
                      ?.replace(/([A-Z])/g, " $1")}
                  </Typography>
                  <Tooltip
                    title={_UserInfo.roles.map((role, index) => (
                      <Typography key={index} variant="body2">
                        {`${index + 1}. ${role.replace(/([A-Z])/g, " $1")}`}
                      </Typography>
                    ))}
                  >
                    <Typography color="text.secondary" variant="body2">
                      <FaInfoCircle size={20} />
                    </Typography>
                  </Tooltip>
                </Stack>
              )
            ) : (
              <Skeleton
                sx={{ width: "100px", height: "7px", margin: 0.8 }}
                variant="rounded"
                animation="wave"
              />
            )}
          </Box>
        </SidebarHeader>
        <SidebarContent style={{ padding: " 0 5px" }}>
          {_UserInfo.id ? (
            <Menu iconShape="circle">
              {items.map(
                (item) =>
                  item.role &&
                  (!item.children ? (
                    <Tooltip
                      key={item.title}
                      title={collapse ? item.title : null}
                      placement="right"
                    >
                      <MenuItem
                        icon={
                          <Icon style={{ color: "white", fontSize: "18px" }}>
                            {item.icon}
                          </Icon>
                        }
                        style={
                          isActive(item.href)
                            ? {
                                backgroundColor: "#e7e6e6",
                                borderRadius: "10px",
                              }
                            : { borderRadius: "10px" }
                        }
                      >
                        {item.title}
                        <Link to={item.href} />
                      </MenuItem>
                    </Tooltip>
                  ) : (
                    <SubMenu
                      key={item.title}
                      title={item.title}
                      style={{ borderRadius: "10px" }}
                      icon={
                        <Icon style={{ color: "white", fontSize: "18px" }}>
                          {item.icon}
                        </Icon>
                      }
                      // suffix={
                      //   <Badge
                      //     color="primary"
                      //     badgeContent={
                      //       item.children.filter((x) => x.role === true).length
                      //     }
                      //     sx={{ marginRight: 2 }}
                      //   ></Badge>
                      // }
                      open={
                        (openConfig && selectedMenu === item.title) ||
                        (openTicket && selectedMenu === item.title) ||
                        (openDeals && selectedMenu === item.title) ||
                        (openVendors && selectedMenu === item.title) ||
                        (openReport && selectedMenu === item.title) ||
                        (openGiftItems && selectedMenu === item.title)
                      }
                      onClick={() => handleMenuClick(item.title)}
                    >
                      {item.children.map(
                        (subItem) =>
                          subItem.role && (
                            <Stack key={subItem.title}>
                              {subItem.title === "Department" ? (
                                <Divider />
                              ) : null}
                              <MenuItem
                                icon={
                                  <Icon
                                    style={{
                                      color: theme.palette.primary.main,
                                      fontSize: "20px",
                                    }}
                                  >
                                    {subItem.icon}
                                  </Icon>
                                }
                                style={
                                  isActive(subItem.href)
                                    ? {
                                        backgroundColor: "#e7e6e6",
                                        borderRadius: "10px",
                                        marginRight: 10,
                                      }
                                    : { borderRadius: "10px", marginRight: 10 }
                                }
                              >
                                {subItem.title}
                                <Link to={subItem.href} />
                              </MenuItem>
                            </Stack>
                          )
                      )}
                    </SubMenu>
                  ))
              )}
            </Menu>
          ) : (
            items.map((item, index) => (
              <Menu
                key={index}
                iconShape="circle"
                style={{ padding: 0, margin: 1.3 }}
              >
                <MenuItem
                  icon={
                    <Skeleton
                      variant="circular"
                      animation="pulse"
                      style={{ padding: 0, margin: 1 }}
                    />
                  }
                >
                  <Skeleton
                    variant="rounded"
                    animation="wave"
                    style={{ padding: 0, margin: 1 }}
                  />
                </MenuItem>
              </Menu>
            ))
          )}
        </SidebarContent>
        <Divider />
        <Box
          sx={{
            p: collapse ? 1 : 2,
          }}
        >
          <RouterLink to="/login">
            <Button
              fullWidth
              variant="contained"
              onClick={(e) => {
                helper.logout();
                cookies.remove("token");
                localStorage.removeItem("decodedIems");
              }}
              startIcon={<InputIcon />}
            >
              {collapse ? null : "Logout"}
            </Button>
          </RouterLink>
        </Box>
        {!collapse && (
          <Stack alignItems="end" justifyContent="end">
            <Footer />
          </Stack>
        )}
      </PerfectScrollbar>
    </ProSidebar>
  );

  return (
    <Box
      sx={{
        top: 64,
        height: "calc(100% - 64px)",
        display: "flex",
        position: "relative",
      }}
    >
      {content}
    </Box>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default DashboardSidebar;
