import { TextField, Grid, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CustomDialog } from "src/components/CustomDialog";
import { LoadingButton } from "@mui/lab";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { openDialog, createGoIp } from "src/actions/goip";

const GoIpForm = () => {
  const dispatch = useDispatch();

  const _GoIp = useSelector((state) => state.goIp);
  const saveGoIpPageNO = useSelector((state) => state.goIp.saveGoIpPageNum);
  const _SearchBody = useSelector((state) => state.goIp.searchBody);

  const [loading, setLoading] = useState(false);

  const {
    control,
    reset,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    defaultValues: _GoIp.form,
  });

  const onSubmit = (data) => {
    setLoading(true);
    dispatch(createGoIp(data.message, saveGoIpPageNO, _SearchBody)).then(() =>
      setLoading(false)
    );
  };

  const handleDialog = () => {
    dispatch(openDialog(null));
  };

  useEffect(() => {
    reset({ ..._GoIp.form });
  }, [_GoIp.form, reset]);

  return (
    <CustomDialog
      title={"Write Message"}
      isOpen={_GoIp.openDialog}
      keepMounted
      handleClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="md"
      Draggable={true}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="message"
              control={control}
              render={({ field }) => (
                <TextField size="small" label="Message" fullWidth {...field} />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Stack
              sx={{ marginTop: 2 }}
              direction="row"
              spacing={2}
              justifyContent="end"
              alignItems="center"
            >
              <LoadingButton
                variant="contained"
                type="submit"
                color="primary"
                loading={loading}
                disabled={!isDirty}
              >
                Save
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </CustomDialog>
  );
};

export default GoIpForm;
