import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Drawer,
  Typography,
  Box,
  Button,
  Grid,
  Stack,
  Switch,
  Checkbox,
  Autocomplete,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Badge,
} from "@mui/material";
import "../theme/custom.scss";
import PerfectScrollbar from "react-perfect-scrollbar";
import { ProSidebar, SidebarHeader, SidebarContent } from "react-pro-sidebar";
import { CustomDialog } from "./CustomDialog";
import CustomText from "./CustomText";
import { useDispatch, useSelector } from "react-redux";
import {
  createNotification,
  getPersonalNotifications,
  notificationUserById,
} from "../actions/notification";
import DateRangepicker from "./DateRangePicker";
import moment from "moment";
import { getAllAddresses } from "src/actions/addresses";
import { createNotificationSegmentation } from "src/actions/notificationSegmentation";
import { Region_Segment_Types } from "../utils/common";
import { LoadingButton } from "@mui/lab";
import { Controller, useForm } from "react-hook-form";
import RolePermission from "src/components/RolePermission";

const NotificationMenu = ({ openNotification, closeNotification }) => {
  // const _UserInfo = useSelector((state) => state.users.userInfo);
  const _PersonalNotifications = useSelector(
    (state) => state.notification.notificationData
  );

  const dispatch = useDispatch();
  const [openNotificationPopUp, setOpenNotificationPopUp] = useState(false);
  const [notification, setNotification] = useState({
    titleEn: "",
    contentEn: "",
    titleAr: "",
    contentAr: "",
    titleKu: "",
    contentKu: "",
    isAndroid: true,
    isIos: true,
  });
  const [viewConfirm, setViewConfirm] = useState(false);
  const [isLoading, setIsloading] = useState(false);

  function onSubmitAll(e) {
    e.preventDefault();
    setViewConfirm(true);
  }

  function createNotifications() {
    setIsloading(true);
    dispatch(createNotification(notification))
      .then((res) => {
        setOpenNotificationPopUp(false);
        setNotification({
          titleEn: "",
          contentEn: "",
          titleAr: "",
          contentAr: "",
          titleKu: "",
          contentKu: "",
          isAndroid: true,
          isIos: true,
        });
        setIsloading(false);
        setViewConfirm(false);
        dispatch(getPersonalNotifications());
      })
      .catch((err) => {
        setIsloading(false);
        setViewConfirm(false);
      });
  }

  ////////////////////////////////////// Notify Group /////////////////////////////

  const addresses = useSelector((state) => state.addresses);

  const [openNotifyGroup, setOpenNotifyGroup] = useState(false);
  const [notificationType, setNotificationType] = useState(4);

  const defaultValues = {
    segmentType: null,
    region: null,
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    provinceId: null,
    cityId: null,
    gender: null,
    hasBalance: false,
    hasOrders: false,
    titleEn: "",
    titleAr: "",
    titleKu: "",
    contentEn: "",
    contentAr: "",
    contentKu: "",
    minWalletBallance: "",
    maxWalletBallance: "",
  };

  const { control, reset, watch, handleSubmit, setValue } = useForm({
    defaultValues: defaultValues,
  });

  useEffect(() => {
    reset({ ...defaultValues });
  }, [openNotifyGroup]);

  const [selectedProvince, setSelectedProvince] = useState({
    nameAR: "",
    nameUS: "",
    cities: [],
    type: 2,
  });

  const gender = [
    { id: null, name: "All" },
    { id: 0, name: "Male" },
    { id: 1, name: "Female" },
  ];

  useEffect(() => {
    notificationType == 0 ? setItemsInCart(true) : setItemsInCart(false);
    notificationType == 1 ? setPreviousOrders(true) : setPreviousOrders(false);
    notificationType == 2 ? setIsUserInfo(true) : setIsUserInfo(false);
    notificationType == 3 ? setBalanceRange(true) : setBalanceRange(false);
  }, [notificationType]);

  const [itemsInCart, setItemsInCart] = useState(false);
  const [previousOrders, setPreviousOrders] = useState(false);
  const [isUserInfo, setIsUserInfo] = useState(false);
  const [balanceRange, setBalanceRange] = useState(false);
  const [segmentData, setSegemantData] = useState({});

  const handleChange = (e) => {
    setNotificationType(e.target.value);
    reset({ ...defaultValues });
    setViewConfirm(false);
  };

  function onSubmit(data) {
    console.log(data);
    setViewConfirm(true);
    setSegemantData(data);
  }

  function sendNotification() {
    setIsloading(true);
    dispatch(
      createNotificationSegmentation({
        segmentType: notificationType,
        region: itemsInCart ? segmentData.region.id : null,
        startDate: previousOrders ? segmentData.startDate : null,
        endDate: previousOrders ? segmentData.endDate : null,
        provinceId: isUserInfo ? segmentData.provinceId : null,
        cityId: isUserInfo ? segmentData.cityId : null,
        gender: isUserInfo ? segmentData.gender.id : null,
        hasBalance: isUserInfo ? segmentData.hasBalance : null,
        hasOrders: isUserInfo ? segmentData.hasOrders : null,
        titleEn: segmentData.titleEn,
        titleKu: segmentData.titleKu,
        titleAr: segmentData.titleAr,
        messageEn: segmentData.contentEn,
        messageAr: segmentData.contentAr,
        messageKu: segmentData.contentKu,
        minWalletBallance: balanceRange
          ? parseFloat(segmentData.minWalletBallance)
          : null,
        maxWalletBalance: balanceRange
          ? parseFloat(segmentData.maxWalletBallance)
          : null,
      })
    )
      .then((res) => {
        if (res.message === "Success") {
          // setOpenNotifyGroup(false);
          reset();
          setIsloading(false);
          setViewConfirm(false);
          setSegemantData({});
          dispatch(getPersonalNotifications());
        }
      })
      .catch((err) => {
        setIsloading(false);
        setViewConfirm(false);
      });
  }

  ////////////////////////////////////// Notify Bu User Id /////////////////////////////

  const [openDialogForUserById, setOpenDialogForUserById] = useState(false);
  const [userByIdContent, setUserByIdContent] = useState({
    titleEn: "",
    contentEn: "",
    titleAr: "",
    contentAr: "",
    titleKu: "",
    contentKu: "",
    userIds: [],
  });
  // const [viewConfirm, setViewConfirm] = useState(false);
  // const [isLoading, setIsloading] = useState(false);

  function onSubmitUserById(e) {
    e.preventDefault();
    setViewConfirm(true);
  }

  function sendUsersById() {
    setIsloading(true);
    dispatch(notificationUserById(userByIdContent))
      .then((res) => {
        setOpenNotificationPopUp(false);
        setUserByIdContent({
          titleEn: "",
          contentEn: "",
          titleAr: "",
          contentAr: "",
          titleKu: "",
          contentKu: "",
          userIds: [],
        });
        setIsloading(false);
        setViewConfirm(false);
        dispatch(getPersonalNotifications());
      })
      .catch((err) => {
        setIsloading(false);
        setViewConfirm(false);
      });
  }

  const content = (
    <ProSidebar>
      <PerfectScrollbar id="bbbb">
        <SidebarHeader style={{ backgroundColor: "white" }}>
          <Box
            sx={{
              alignItems: "center",
              p: 1,
            }}
          >
            <Stack
              direction="column"
              spacing={2}
              sx={{ alignContent: "cenyter" }}
            >
              <RolePermission permission="19-01">
                <Button
                  onClick={() => {
                    setOpenNotificationPopUp(true);
                  }}
                  variant="contained"
                  fullWidth
                >
                  Notify All
                </Button>
              </RolePermission>
              <RolePermission permission="20-01">
                <Button
                  onClick={() => {
                    setOpenNotifyGroup(true);
                    dispatch(getAllAddresses());
                  }}
                  variant="contained"
                  fullWidth
                >
                  Notify Group
                </Button>
              </RolePermission>
              <RolePermission permission="19-02">
                <Button
                  onClick={() => {
                    setOpenDialogForUserById(true);
                  }}
                  variant="contained"
                  fullWidth
                >
                  Notify By User Id
                </Button>
              </RolePermission>
            </Stack>
          </Box>
        </SidebarHeader>
        <SidebarContent style={{ backgroundColor: "white", padding: " 0 5px" }}>
          {_PersonalNotifications?.length <= 0 ? (
            <Typography
              style={{ position: "absolute", top: "50%", left: "35%" }}
              color="text.secondary"
              variant="body2"
            >
              No Notifications
            </Typography>
          ) : (
            <Stack>
              {/* {_PersonalNotifications.some((x) => x.isRead === false) ? (
                <Tooltip title="Mark all as read">
                  <Button
                    sx={{ my: 3 }}
                    color="secondary"
                    variant="contained"
                    onClick={(e) => {
                      dispatch(markAllNotificationsAsRead());
                    }}
                  >
                    Mark all as read
                  </Button>
                </Tooltip>
              ) : null} */}
              {_PersonalNotifications?.map((notification, index) => (
                <Stack
                  key={index}
                  spacing={2}
                  sx={{
                    m: 1,
                    p: 1,
                    border: "1px solid #D3D3D3",
                    borderRadius: "15px",
                  }}
                >
                  {!notification.isRead ? (
                    <Badge color="error" variant="dot">
                      <Typography variant="h5">
                        {notification.title
                          ? notification.title
                          : "New Notification"}
                      </Typography>
                    </Badge>
                  ) : (
                    <Typography variant="h5">
                      {notification.title
                        ? notification.title
                        : "New Notification"}
                    </Typography>
                  )}
                  <Typography variant="body2">{notification.body}</Typography>
                  <Typography variant="body2" align="right">
                    {moment(notification.createdAt).format(
                      "YYYY-MM-DD / hh:mm a"
                    )}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          )}
        </SidebarContent>
      </PerfectScrollbar>
    </ProSidebar>
  );

  return (
    <>
      <CustomDialog
        isOpen={openNotificationPopUp}
        handleClose={() => {
          setOpenNotificationPopUp(false);
          setNotification({
            titleEn: "",
            contentEn: "",
            titleAr: "",
            contentAr: "",
            titleKu: "",
            contentKu: "",
            isAndroid: true,
            isIos: true,
          });
          setViewConfirm(false);
          setIsloading(false);
        }}
        maxWidth="lg"
        title="Notify All"
      >
        <form onSubmit={onSubmitAll}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack direction="row" spacing={4} sx={{ alignItems: "center" }}>
                <Stack
                  direction="row"
                  spacing={0}
                  sx={{ alignItems: "center" }}
                >
                  <Checkbox
                    color="primary"
                    checked={notification.isAndroid}
                    onChange={(e) =>
                      setNotification({
                        ...notification,
                        isAndroid: e.target.checked,
                      })
                    }
                  />
                  <Typography>Android</Typography>
                </Stack>
                <Stack
                  direction="row"
                  spacing={0}
                  sx={{ alignItems: "center" }}
                >
                  <Checkbox
                    color="primary"
                    checked={notification.isIos}
                    onChange={(e) =>
                      setNotification({
                        ...notification,
                        isIos: e.target.checked,
                      })
                    }
                  />
                  <Typography>IOS</Typography>
                </Stack>
              </Stack>
            </Grid>

            <Grid item xs={6}>
              <CustomText
                required
                label="English Title"
                multiline
                value={notification.titleEn}
                onChange={(e) => {
                  setNotification({
                    ...notification,
                    titleEn: e.target.value,
                  });
                }}
                style={{ margin: "5px" }}
                fullWidth
                inputProps={{ minLength: 5 }}
                helperText={
                  notification.titleEn?.length < 5
                    ? "Title should at least 5 characters"
                    : null
                }
              />
              <CustomText
                required
                label="English Content"
                multiline
                value={notification.contentEn}
                onChange={(e) => {
                  setNotification({
                    ...notification,
                    contentEn: e.target.value,
                  });
                }}
                style={{ margin: "5px" }}
                fullWidth
                rows="12"
                inputProps={{ minLength: 10 }}
                helperText={
                  notification.contentEn?.length < 10
                    ? "Content should at least 10 characters"
                    : null
                }
              />
            </Grid>
            <Grid item xs={6}>
              <CustomText
                required
                label="العنوان"
                multiline
                value={notification.titleAr}
                onChange={(e) => {
                  setNotification({
                    ...notification,
                    titleAr: e.target.value,
                  });
                }}
                style={{ margin: "5px", direction: "rtl" }}
                fullWidth
                inputProps={{ minLength: 5 }}
                helperText={
                  notification.titleAr?.length < 5
                    ? "العنوان يحب ان لا يكون اقل من 5 احرف"
                    : null
                }
              />
              <CustomText
                required
                label="المحتوى"
                multiline
                value={notification.contentAr}
                onChange={(e) => {
                  setNotification({
                    ...notification,
                    contentAr: e.target.value,
                  });
                }}
                style={{ margin: "5px", direction: "rtl" }}
                fullWidth
                rows="12"
                inputProps={{ minLength: 10 }}
                helperText={
                  notification.contentAr?.length < 10
                    ? "المحتوي يحب ان لا يكون اقل من 10 احرف"
                    : null
                }
              />
            </Grid>
            <Grid item xs={6}>
              <CustomText
                required
                label="Title Kurdish"
                multiline
                value={notification.titleKu}
                onChange={(e) => {
                  setNotification({
                    ...notification,
                    titleKu: e.target.value,
                  });
                }}
                style={{ margin: "5px", direction: "rtl" }}
                fullWidth
                inputProps={{ minLength: 5 }}
                helperText={
                  notification.titleKu?.length < 5
                    ? "Title should at least 5 characters"
                    : null
                }
              />
              <CustomText
                required
                label="Content Kurdish"
                multiline
                value={notification.contentKu}
                onChange={(e) => {
                  setNotification({
                    ...notification,
                    contentKu: e.target.value,
                  });
                }}
                style={{ margin: "5px", direction: "rtl" }}
                fullWidth
                rows="12"
                inputProps={{ minLength: 10 }}
                helperText={
                  notification.contentKu?.length < 10
                    ? "Content should at least 10 characters"
                    : null
                }
              />
            </Grid>
            <Grid item xs={12}>
              {viewConfirm ? (
                <Stack
                  direction={"row"}
                  spacing={2}
                  sx={{
                    marginTop: 2,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography>
                    Are you sure you want to send notification ?
                  </Typography>
                  <LoadingButton
                    variant="contained"
                    onClick={() => {
                      createNotifications();
                    }}
                    color="success"
                    loading={isLoading}
                  >
                    Confirm
                  </LoadingButton>
                  <Button
                    variant="contained"
                    onClick={() => setViewConfirm(false)}
                    color="error"
                  >
                    Cancel
                  </Button>
                </Stack>
              ) : (
                <Grid style={{ position: "relative", left: "35%" }} item xs={3}>
                  <Button
                    style={{ position: "relative" }}
                    variant="contained"
                    sx={{ margin: "5px" }}
                    type="submit"
                    fullWidth
                  >
                    Send
                  </Button>
                  <Button
                    style={{ position: "relative" }}
                    color="secondary"
                    variant="contained"
                    sx={{ margin: "5px" }}
                    onClick={() => {
                      setNotification({
                        titleEn: "",
                        contentEn: "",
                        titleAr: "",
                        contentAr: "",
                        titleKu: "",
                        contentKu: "",
                        isAndroid: true,
                        isIos: true,
                      });
                    }}
                    fullWidth
                  >
                    Clear All
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </form>
      </CustomDialog>
      <CustomDialog
        isOpen={openNotifyGroup}
        handleClose={() => {
          setOpenNotifyGroup(false);
          setViewConfirm(false);
          setNotificationType(3);
          reset({ ...defaultValues });
          setIsloading(false);
        }}
        maxWidth="lg"
        title="Notify Group"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2} sx={{ paddingTop: 1 }}>
                <Grid item xs={6}>
                  {itemsInCart && (
                    <Stack
                      direction="row"
                      spacing={4}
                      sx={{
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <Controller
                        name="region"
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            options={Region_Segment_Types}
                            getOptionLabel={(option) => option.name}
                            fullWidth
                            size="small"
                            renderInput={(params) => (
                              <TextField required {...params} label="Region" />
                            )}
                            onChange={(event, value) => {
                              setValue("region", value ? value : null);
                            }}
                            value={field.value}
                          />
                        )}
                      />
                    </Stack>
                  )}
                  {previousOrders && (
                    <Box sx={{ paddingLeft: 1 }}>
                      <Controller
                        name="startDate"
                        control={control}
                        render={({ field }) => (
                          <DateRangepicker
                            startDate={watch().startDate}
                            endDate={watch().endDate}
                            handleOnChange={(searchDate) => {
                              setValue(
                                "startDate",
                                moment(searchDate[0].startDate).format(
                                  "YYYY-MM-DD"
                                )
                              );
                              setValue(
                                "endDate",
                                moment(searchDate[0].endDate).format(
                                  "YYYY-MM-DD"
                                )
                              );
                            }}
                          />
                        )}
                      />
                    </Box>
                  )}
                  {isUserInfo && (
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Controller
                          name="provinceId"
                          control={control}
                          render={({ field }) => (
                            <Autocomplete
                              options={addresses}
                              getOptionLabel={(option) =>
                                option.nameAR
                                  ? `${option.nameAR} , ${option.nameUS}`
                                  : "Not selected"
                              }
                              fullWidth
                              size="small"
                              renderInput={(params) => (
                                <TextField {...params} label="Provinces" />
                              )}
                              onChange={(event, value) => {
                                setValue("provinceId", value ? value.id : null);
                                setSelectedProvince(
                                  value
                                    ? value
                                    : { nameAR: "", nameUS: "", cities: [] }
                                );
                              }}
                              value={
                                addresses.filter((x) => x.id == field.value)[0]
                                  ? addresses.filter(
                                      (x) => x.id == field.value
                                    )[0]
                                  : null
                              }
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Controller
                          name="cityId"
                          control={control}
                          render={({ field }) => (
                            <Autocomplete
                              options={selectedProvince.cities}
                              getOptionLabel={(option) =>
                                option.nameAR
                                  ? `${option.nameAR} , ${option.nameUS}`
                                  : "Not selected"
                              }
                              fullWidth
                              size="small"
                              renderInput={(params) => (
                                <TextField {...params} label="Cities" />
                              )}
                              onChange={(event, value) => {
                                setValue("cityId", value ? value.id : null);
                              }}
                              value={
                                addresses
                                  .map((city) => city.cities)
                                  .flat()
                                  .filter((x) => x.id == field.value)[0]
                                  ? addresses
                                      .map((city) => city.cities)
                                      .flat()
                                      .filter((x) => x.id == field.value)[0]
                                  : null
                              }
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Controller
                          name="gender"
                          control={control}
                          render={({ field }) => (
                            <Autocomplete
                              options={gender}
                              getOptionLabel={(option) => option.name}
                              fullWidth
                              size="small"
                              renderInput={(params) => (
                                <TextField
                                  required
                                  {...params}
                                  label="Gender"
                                />
                              )}
                              onChange={(event, value) => {
                                setValue("gender", value ? value : null);
                              }}
                              value={field.value}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Controller
                          name="hasBalance"
                          control={control}
                          render={({ field }) => (
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={field.value}
                                  onChange={(e) => {
                                    setValue("hasBalance", e.target.checked);
                                  }}
                                />
                              }
                              label="Has Balance!"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Controller
                          name="hasOrders"
                          control={control}
                          render={({ field }) => (
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={field.value}
                                  onChange={(e) => {
                                    setValue("hasOrders", e.target.checked);
                                  }}
                                />
                              }
                              label="Has Order!"
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {balanceRange && (
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Controller
                          name="minWalletBallance"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              required
                              size="small"
                              label="Min Balance"
                              fullWidth
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Controller
                          name="maxWalletBallance"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              required
                              size="small"
                              label="Max Balance"
                              fullWidth
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Select Type</InputLabel>
                    <Select
                      value={notificationType}
                      label="Select Type"
                      onChange={handleChange}
                    >
                      <MenuItem value={4} sx={{ color: "gray" }}>
                        Select Type
                      </MenuItem>
                      <MenuItem value={0}>Items in cart</MenuItem>
                      <MenuItem value={1}>Previous orders</MenuItem>
                      <MenuItem value={2}>User Information</MenuItem>
                      <MenuItem value={3}>Wallet Balance Range</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} sx={{ marginBottom: 6 }}>
              <Stack
                direction="column"
                spacing={1}
                sx={{ justifyContent: "center" }}
              >
                <Controller
                  name="titleEn"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      sx={{ marginBottom: 0.5 }}
                      required
                      size="small"
                      label="English Title"
                      inputProps={{ minLength: 5 }}
                      fullWidth
                      {...field}
                      helperText={
                        watch().titleEn.length < 5
                          ? "Title should at least 5 characters"
                          : null
                      }
                    />
                  )}
                />
                <Controller
                  name="contentEn"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      multiline
                      required
                      size="small"
                      label="English Content"
                      inputProps={{ minLength: 10 }}
                      fullWidth
                      {...field}
                      rows="12"
                      helperText={
                        watch().contentEn.length < 10
                          ? "Content should at least 10 characters"
                          : null
                      }
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={6} sx={{ marginBottom: 6 }}>
              <Stack
                direction="column"
                spacing={1}
                sx={{ justifyContent: "center" }}
              >
                <Controller
                  name="titleAr"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      sx={{ direction: "rtl", marginBottom: 0.5 }}
                      inputProps={{ minLength: 5 }}
                      required
                      size="small"
                      label="العنوان"
                      fullWidth
                      {...field}
                      helperText={
                        watch().titleAr.length < 5
                          ? "العنوان يحب ان لا يكون اقل من 5 احرف"
                          : null
                      }
                    />
                  )}
                />
                <Controller
                  name="contentAr"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      sx={{ direction: "rtl" }}
                      inputProps={{ minLength: 10 }}
                      multiline
                      required
                      size="small"
                      label="المحتوى"
                      fullWidth
                      {...field}
                      rows="12"
                      helperText={
                        watch().contentAr.length < 10
                          ? "المحتوى يحب ان لا يكون اقل من 10 احرف"
                          : null
                      }
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack
                direction="column"
                spacing={1}
                sx={{ justifyContent: "center" }}
              >
                <Controller
                  name="titleKu"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      sx={{ marginBottom: 0.5 }}
                      required
                      size="small"
                      label="Kurdish Title"
                      inputProps={{ minLength: 5 }}
                      fullWidth
                      {...field}
                      helperText={
                        watch().titleKu.length < 5
                          ? "Title should at least 5 characters"
                          : null
                      }
                    />
                  )}
                />
                <Controller
                  name="contentKu"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      multiline
                      required
                      size="small"
                      label="Kurdish Content"
                      inputProps={{ minLength: 10 }}
                      fullWidth
                      {...field}
                      rows="12"
                      helperText={
                        watch().contentKu.length < 10
                          ? "Content should at least 10 characters"
                          : null
                      }
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              {viewConfirm ? (
                <Stack
                  direction={"row"}
                  spacing={2}
                  sx={{
                    marginTop: 2,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography>
                    Are you sure you want to send notification ?
                  </Typography>
                  <LoadingButton
                    variant="contained"
                    color="success"
                    loading={isLoading}
                    onClick={() => sendNotification()}
                  >
                    Confirm
                  </LoadingButton>
                  <Button
                    variant="contained"
                    onClick={() => setViewConfirm(false)}
                    color="error"
                  >
                    Cancel
                  </Button>
                </Stack>
              ) : (
                <Grid style={{ position: "relative", left: "35%" }} item xs={3}>
                  <Button
                    style={{ position: "relative" }}
                    variant="contained"
                    sx={{ margin: "5px" }}
                    type="submit"
                    fullWidth
                  >
                    Send
                  </Button>
                  <Button
                    style={{ position: "relative" }}
                    color="secondary"
                    variant="contained"
                    sx={{ margin: "5px" }}
                    onClick={() => {
                      reset({ ...defaultValues });
                    }}
                    fullWidth
                  >
                    Clear All
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </form>
      </CustomDialog>
      <CustomDialog
        isOpen={openDialogForUserById}
        handleClose={() => {
          setOpenDialogForUserById(false);
          setUserByIdContent({
            titleEn: "",
            contentEn: "",
            titleAr: "",
            contentAr: "",
            titleKu: "",
            contentKu: "",
            userIds: [],
          });
          setViewConfirm(false);
          setIsloading(false);
        }}
        maxWidth="lg"
        title="Notify Group (USERS IDS)"
      >
        <form onSubmit={onSubmitUserById}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <CustomText
                required
                label="English Title"
                multiline
                value={userByIdContent.titleEn}
                onChange={(e) => {
                  setUserByIdContent({
                    ...userByIdContent,
                    titleEn: e.target.value,
                  });
                }}
                style={{ margin: "5px" }}
                fullWidth
                inputProps={{ minLength: 5 }}
                helperText={
                  userByIdContent.titleEn?.length < 5
                    ? "Title should at least 5 characters"
                    : null
                }
              />
              <CustomText
                required
                label="English Content"
                multiline
                value={userByIdContent.contentEn}
                onChange={(e) => {
                  setUserByIdContent({
                    ...userByIdContent,
                    contentEn: e.target.value,
                  });
                }}
                style={{ margin: "5px" }}
                fullWidth
                rows="8"
                inputProps={{ minLength: 10 }}
                helperText={
                  userByIdContent.contentEn?.length < 10
                    ? "Content should at least 10 characters"
                    : null
                }
              />
            </Grid>
            <Grid item xs={6}>
              <CustomText
                required
                label="العنوان"
                multiline
                value={userByIdContent.titleAr}
                onChange={(e) => {
                  setUserByIdContent({
                    ...userByIdContent,
                    titleAr: e.target.value,
                  });
                }}
                style={{ margin: "5px", direction: "rtl" }}
                fullWidth
                inputProps={{ minLength: 5 }}
                helperText={
                  userByIdContent.titleAr?.length < 5
                    ? "العنوان يحب ان لا يكون اقل من 5 احرف"
                    : null
                }
              />
              <CustomText
                required
                label="المحتوى"
                multiline
                value={userByIdContent.contentAr}
                onChange={(e) => {
                  setUserByIdContent({
                    ...userByIdContent,
                    contentAr: e.target.value,
                  });
                }}
                style={{ margin: "5px", direction: "rtl" }}
                fullWidth
                rows="8"
                inputProps={{ minLength: 10 }}
                helperText={
                  userByIdContent.contentAr?.length < 10
                    ? "المحتوي يحب ان لا يكون اقل من 10 احرف"
                    : null
                }
              />
            </Grid>
            <Grid item xs={6}>
              <CustomText
                required
                label="Title Kurdish"
                multiline
                value={userByIdContent.titleKu}
                onChange={(e) => {
                  setUserByIdContent({
                    ...userByIdContent,
                    titleKu: e.target.value,
                  });
                }}
                style={{ margin: "5px", direction: "rtl" }}
                fullWidth
                inputProps={{ minLength: 5 }}
                helperText={
                  userByIdContent.titleKu?.length < 5
                    ? "Title should at least 5 characters"
                    : null
                }
              />
              <CustomText
                required
                label="Content Kurdish"
                multiline
                value={userByIdContent.contentKu}
                onChange={(e) => {
                  setUserByIdContent({
                    ...userByIdContent,
                    contentKu: e.target.value,
                  });
                }}
                style={{ margin: "5px", direction: "rtl" }}
                fullWidth
                rows="12"
                inputProps={{ minLength: 10 }}
                helperText={
                  userByIdContent.contentKu?.length < 10
                    ? "Content should at least 10 characters"
                    : null
                }
              />
            </Grid>
            <Grid item xs={12}>
              <CustomText
                required
                label="User Ids"
                multiline
                value={userByIdContent.userIds.join(", ")}
                onChange={(e) => {
                  setUserByIdContent({
                    ...userByIdContent,
                    userIds: e.target.value
                      .replace(/\s+/g, " ")
                      .split(",")
                      .map((id) => id.trim()),
                  });
                }}
                style={{ margin: "5px" }}
                fullWidth
                rows="8"
                inputProps={{ minLength: 32 }}
              />
            </Grid>
            <Grid item xs={12}>
              {viewConfirm ? (
                <Stack
                  direction={"row"}
                  spacing={2}
                  sx={{
                    marginTop: 2,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography>
                    Are you sure you want to send notification ?
                  </Typography>
                  <LoadingButton
                    variant="contained"
                    onClick={() => {
                      sendUsersById();
                    }}
                    color="success"
                    loading={isLoading}
                  >
                    Confirm
                  </LoadingButton>
                  <Button
                    variant="contained"
                    onClick={() => setViewConfirm(false)}
                    color="error"
                  >
                    Cancel
                  </Button>
                </Stack>
              ) : (
                <Grid style={{ position: "relative", left: "35%" }} item xs={3}>
                  <Button
                    style={{ position: "relative" }}
                    variant="contained"
                    sx={{ margin: "5px" }}
                    type="submit"
                    fullWidth
                  >
                    Send
                  </Button>
                  <Button
                    style={{ position: "relative" }}
                    color="secondary"
                    variant="contained"
                    sx={{ margin: "5px" }}
                    onClick={() => {
                      setUserByIdContent({
                        titleEn: "",
                        contentEn: "",
                        titleAr: "",
                        contentAr: "",
                        titleKu: "",
                        contentKu: "",
                        userIds: [],
                      });
                    }}
                    fullWidth
                  >
                    Clear All
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </form>
      </CustomDialog>
      <Drawer
        anchor="right"
        open={openNotification}
        onClose={closeNotification}
        variant="temporary"
        PaperProps={{
          sx: {
            width: 275,
            top: 64,
            height: "calc(100% - 64px)",
          },
        }}
      >
        {content}
      </Drawer>
    </>
  );
};

NotificationMenu.propTypes = {
  closeNotification: PropTypes.func,
  openNotification: PropTypes.bool,
};

NotificationMenu.defaultProps = {
  closeNotification: () => {},
  openNotification: false,
};
export default NotificationMenu;
